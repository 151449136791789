<template>
  <div class="container">
    <Breadcrumb :data="nav" :is$t="true" />
    <Box v-loading="loading" class="pane">
      <div slot="header" class="pane-header">
        <h4 class="title">{{ detail.name }}</h4>
        <p class="sub-title">
          <span>作者：</span>
          <span class="value">{{ detail.author }}</span>
          <span>来源：</span>
          <span class="value">{{ detail.source }}</span>
          <span>发表时间：</span>
          <span>{{ detail.dateline }}</span>
        </p>
      </div>
      <div class="pane-body">
        <div class="ql-editor" v-html="detail.content"></div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Breadcrumb from '@/components/Breadcrumb'
import API from "@/api/legalResearchResult"
export default {
  name: 'ResearchResultDetails',
  components: {
    Box, API,
    Breadcrumb,
  },
  data() {
    return {
      loading: false,
      detail: {}
    }
  },
  computed: {
    nav() {
      return [
        { name: '首页', path: '/home' },
        { name: '法律研究成果', path: '/researchResult/list' },
        { name: this.detail.name, path: '' }
      ]
    }
  },
  watch: {
    // thinkData(newVal) {
    //   if (newVal) {
    //     this.pageInfo.total = newVal.page.total
    //   }
    // },
    // getType(newVal) {
    //   this.resetPageInfo()
    // },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const id = this.$route.query?.id || ''
      API.getLegalResearchResultDetails(id).then(res => {
        this.detail = res
      })
    }
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.container {
  width: @uni-width;
  min-height: calc(100vh - 334px);
  margin: 20px auto;

  .pane-header {
    padding: 30px;
    border-bottom: 1px solid #eee;

    .title {
      font-size: 20px;
      color: #333333;
      line-height: 30px;
      font-weight: 500;
      text-align: center;
      padding-bottom: 30px;
    }

    .value{
      margin-right: 40px;
    }
    .sub-title {
      font-size: 14px;
      color: #999999;
      text-align: center;
    }
  }
  .pane-body {
    font-size: 14px;
    color: #333333;
    line-height: 1.5;
    min-height: calc(100vh - 533px);
    p {
      padding-bottom: 30px;
    }
  }
}
</style>
